import { DialogService } from 'aurelia-dialog';
import { ServiceDialog } from './components/service-dialog/service-dialog';
import { connectTo } from 'aurelia-store';
import 'app.scss';
import './images/notlogg_favicon.ico';
import { EventAggregator } from 'aurelia-event-aggregator';
import { LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router, RouterConfiguration, RouterEvent } from 'aurelia-router';
import 'normalize.css';
import { AccountService } from 'services/account-service';
import { SearchService } from 'services/search-service';
import { ToastService } from 'services/toast-service';
import { UserService } from 'services/user-service';
import { AuthorizeStep } from './authorizeStep';
import { Role, UserModels } from './models/UserModels';
import routes from './routes';
import * as moment from 'moment';
import { Logger } from 'aurelia-logging';
import { ApplicationStateManager, ApplicationState } from 'lib/state';
import { PubSub } from 'lib/event/PubSub';
import { getListSettings } from 'elements/list-settings';
import * as Sentry from '@sentry/browser';
const logger: Logger = LogManager.getLogger('app');

@autoinject
@connectTo()
export class App {
  private ApplicationMajorVersion = 7;
  protected version = window?.__netlog?.release?.slice(0, 12) || 'unknown';
  //

  private sidebarLeftVisibleMobile = false;
  private sidebarRightVisible: boolean;
  private searchBoxExpanded: boolean;
  private isLoggedIn = false;
  private userData: UserModels.User;
  private searchString: string;

  private focusedSearchResultItem = 0;
  private focusedSearchResultEntity = 0;

  private keepMenuOpen = true;
  private keepMenuOpenCheckbox = true;

  protected searchBoxFocused: boolean;
  protected searchBoxClass: string;
  protected searchStringToShort = false;
  protected viewPortSideClass = '';
  protected isSearching = false;
  private searchResults: any = false;
  protected boundHandleAppHotkeys;

  menuStructure: any;
  activeRouteBase: string;

  state: ApplicationState;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private userService: UserService,
    private toastService: ToastService,
    private searchService: SearchService,
    private dialogService: DialogService,
    private i18n: I18N,
    private appState: ApplicationStateManager,
    pubsub: PubSub
  ) {
    pubsub.sub('list-settings:settings-changed', () => {
      void appState.mutate({ action: 'setListSettings', payload: getListSettings() });
    });

    this.sidebarLeftVisibleMobile = false;
    this.sidebarRightVisible = false;
    this.searchBoxExpanded = false;
    this.searchBoxClass = '';

    const keepMenuOpenValue = localStorage.getItem('KEEP_MENU_OPEN') === 'false' ? false : true;
    this.keepMenuOpen = keepMenuOpenValue;
    this.keepMenuOpenCheckbox = keepMenuOpenValue;
    this.appState.mutate({ action: 'setSidebarOpen', payload: this.keepMenuOpen });

    AuthorizeStep.auth.isAuthenticated = this.accountService.isAuthenticated();

    const localStorageLanguage = localStorage.getItem('netlog_language');
    if (localStorageLanguage) {
      void this.i18n.setLocale(localStorageLanguage);

      if (localStorageLanguage === 'en') {
        moment.locale('en');
      } else if (localStorageLanguage === 'nb-NO') {
        moment.locale('nb');
      } else if (localStorageLanguage === 'es') {
        moment.locale('es');
      } else {
        moment.locale('nb');
      }
    } else {
      void this.i18n.setLocale('nb-NO');
      moment.locale('nb');
    }

    // check version, might need to clear local storage if a major version is detected
    this.checkMajorVersionUpdate();

    // hack to make translation of module names work - does not work if triggered without a delay
    setTimeout(() => {
      this.translateRoutes();
    }, 1000);

    // Subscribe to route change
    this.eventAggregator.subscribe(RouterEvent.Complete, (event) => {
      this.activeRouteBase = event?.instruction?.config?.route_base;
    });
  }

  setupMenuStructure() {
    const routes = this.router.navigation?.filter((it) => !!it.config?.section);

    const sections = [
      {
        id: 'net',
        title: this.i18n.tr('menu.net'),
        colorScheme: 'red',
      },
      {
        id: 'service',
        title: this.i18n.tr('general.service'),
        colorScheme: 'blue',
      },
      {
        id: 'products',
        title: this.i18n.tr('menu.products'),
        colorScheme: 'teal',
      },
      {
        id: 'crm',
        title: this.i18n.tr('menu.crm'),
        colorScheme: 'purple',
      },
      {
        id: 'storage',
        title: this.i18n.tr('menu.storage'),
        colorScheme: 'yellow',
      },
      {
        id: 'admin',
        title: this.i18n.tr('general.management'),
        colorScheme: 'green',
      },
      {
        id: 'mooring',
        title: this.i18n.tr('menu.mooring'),
        colorScheme: 'gray',
      },
    ];

    const menuStructure = sections.map((it) => {
      return {
        ...it,
        children: routes.filter((route) => route.config.section === it.id),
      };
    });

    this.menuStructure = menuStructure;
  }

  private checkMajorVersionUpdate() {
    const currentMajorVersionInfo = localStorage.getItem('MAJOR_VERSION');
    if (!currentMajorVersionInfo || currentMajorVersionInfo != this.ApplicationMajorVersion.toString()) {
      logger.warn('Major update detected, need to clear some of the settings in localStorage..');

      this.userService.clearUserSettings();

      localStorage.setItem('MAJOR_VERSION', this.ApplicationMajorVersion.toString());
    } else {
      logger.debug('No major upgrade detected, keep settings as is');
    }
  }

  async configureRouter(config: RouterConfiguration, router: Router) {
    await router.configure(routes);
    this.translateRoutes();

    config.addAuthorizeStep(AuthorizeStep);

    if (this.accountService.isAuthenticated()) {
      AuthorizeStep.auth.isAuthenticated = true;
      this.isLoggedIn = AuthorizeStep.auth.isAuthenticated;
      await this.getUserData();
      await this.appState.mutate({ action: 'setAuthenticated', payload: this.isLoggedIn });
    }

    this.eventAggregator.subscribe('isLoggedIn', async (response: boolean) => {
      AuthorizeStep.auth.isAuthenticated = response;
      this.isLoggedIn = AuthorizeStep.auth.isAuthenticated;
      console.log('isLoggedIn');

      if (this.isLoggedIn) {
        await this.getUserData();
      } else {
        this.userData = null;
        AuthorizeStep.auth.roles = null;
      }
      await this.appState.mutate({ action: 'setAuthenticated', payload: this.isLoggedIn });
    });

    this.eventAggregator.subscribe('lang-changed', () => {
      this.translateRoutes();
    });

    this.eventAggregator.subscribe('router:navigation:success', (response: any) => {
      this.keepMenuOpen = this.keepMenuOpenCheckbox;

      if (response.instruction.config.viewPorts.side.moduleId !== null) {
        this.viewPortSideClass = 'visible';
      } else {
        this.viewPortSideClass = '';
      }
    });

    this.eventAggregator.subscribe('roles-loaded', () => {
      // set property on route to make it easier to show/hide correct items in the menu
      this.router.routes.forEach((route) => {
        if (route.navModel.settings && route.navModel.settings.roles && route.navModel.settings.roles.length > 0) {
          // route has roles defined, check if user has access or is administrator
          route.navModel.settings.availableForUser = false;

          if (AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
            route.navModel.settings.availableForUser = true;
          } else {
            route.navModel.settings.roles.forEach((allowedRole: Role) => {
              if (AuthorizeStep.auth.roles.indexOf(allowedRole) !== -1) {
                route.navModel.settings.availableForUser = true;
              }
            });
          }
        } else {
          // no roles set, available for all users
          route.navModel.settings.availableForUser = true;
        }
      });
    });
  }

  activate() {
    this.boundHandleAppHotkeys = (evt: KeyboardEvent) => this.handleAppHotkeys(evt);
    window.addEventListener('keydown', this.boundHandleAppHotkeys);
  }

  getRoute() {
    // logger.debug(this.router.currentInstruction.config.name); //name of the route
    // return this.router.currentInstruction.config.moduleId; //moduleId of the route
  }

  private toggleSearchBox() {
    this.searchBoxExpanded = !this.searchBoxExpanded;
    if (this.searchBoxExpanded) {
      this.searchBoxClass = 'expanded';
      this.searchBoxFocused = true;
    } else {
      this.searchBoxClass = '';
      this.clearSearch();
    }
  }

  toggleRightSidebar() {
    this.sidebarRightVisible = !this.sidebarRightVisible;
  }

  toggleLeftSidebar() {
    this.sidebarLeftVisibleMobile = !this.sidebarLeftVisibleMobile;
  }

  async logout() {
    await this.accountService.logout();
    Sentry.setUser(null);

    this.eventAggregator.publish('isLoggedIn', false);
    this.userService.clearCurrentUser();
    this.sidebarRightVisible = false;
    this.router.navigate('login/loggedout');
  }

  clearUserSettings() {
    this.userService.clearUserSettings();
    this.toastService.showSuccess('general.userSettingsCleared');
    location.reload();
  }

  private async getUserData() {
    const user = await this.userService.getCurrentUser();
    const roles = await this.userService.getRolesForUser(user.Id);

    this.userData = user;

    Sentry.setUser({
      id: user.Id,
    });

    await this.appState.mutate({ action: 'setUser', payload: user });
    await this.appState.mutate({ action: 'setRoles', payload: roles });

    if (this.i18n.getLocale() !== this.userData.Language) {
      localStorage.setItem('netlog_language', this.userData.Language);
      await this.i18n.setLocale(this.userData.Language || 'en');

      if (this.userData.Language === 'en') {
        moment.locale('en');
      } else if (this.userData.Language === 'nb-NO') {
        moment.locale('nb');
      } else if (this.userData.Language === 'es') {
        moment.locale('es');
      } else {
        moment.locale('nb');
      }
    }

    AuthorizeStep.auth.userData = this.userData;
    AuthorizeStep.auth.roles = roles;

    setTimeout(() => {
      this.eventAggregator.publish('roles-loaded');
    });
  }

  private translateRoutes() {
    // set property on route to make it easier to show/hide correct items in the menu
    this.router.routes.forEach((route) => {
      // add translated version of title, used for searching for modules
      if (route.navModel.title) {
        // tslint:disable-next-line:no-string-literal
        route.navModel['titleTranslated'] = this.i18n.tr(route.navModel.title);
      }
    });

    this.setupMenuStructure();
  }

  handleSearchResult(event: KeyboardEvent) {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp' || event.code === 'Enter') {
      if (this.searchResults.length > 0) {
        if (
          event.code === 'ArrowDown' &&
          // check if there are more entity results
          this.searchResults.length > this.focusedSearchResultEntity + 1 &&
          // check if vi are on last item within current entity
          this.searchResults[this.focusedSearchResultEntity].Result.length === this.focusedSearchResultItem + 1 &&
          // check that we are not last entity result
          this.focusedSearchResultEntity + 1 !== this.searchResults.length
        ) {
          this.focusedSearchResultEntity++;
          this.focusedSearchResultItem = 0;
          return true;
        }
        if (
          event.code === 'ArrowDown' &&
          this.searchResults[this.focusedSearchResultEntity].Result.length > this.focusedSearchResultItem + 1
        ) {
          this.focusedSearchResultItem++;
          return true;
        }

        if (event.code === 'ArrowUp' && this.focusedSearchResultEntity > 0 && this.focusedSearchResultItem === 0) {
          this.focusedSearchResultEntity--;
          this.focusedSearchResultItem = this.searchResults[this.focusedSearchResultEntity].Result.length - 1;
          return true;
        }

        if (event.code === 'ArrowUp' && this.focusedSearchResultItem > 0) {
          this.focusedSearchResultItem--;
          return true;
        }

        if (event.code === 'Enter') {
          const res = this.searchResults[this.focusedSearchResultEntity].Result[this.focusedSearchResultItem];
          this.goToSearchResult(res);
          this.clearSearch();
          return true;
        }
      }
      return true;
    }

    return true;
  }

  search(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.clearSearch();
      return true;
    }

    if (event.code === 'ArrowDown' || event.code === 'ArrowUp' || event.code === 'Enter') {
      return true;
    } else {
      switch (true) {
        case !this.searchString || this.searchString.length < 2:
          this.searchStringToShort = true;
          this.searchResults = [];
          break;

        case this.searchString.length > 2:
          this.searchStringToShort = false;

          this.isSearching = true;

          this.searchService
            .search(this.searchString)
            .then((res) => {
              this.setFocusedSearchResult(0, 0);
              this.searchResults = res;
              this.isSearching = false;
            })
            .catch(() => {
              this.isSearching = false;
            });

          break;
      }

      return true;
    }
  }

  private goToSearchResult(res) {
    switch (res.Entity) {
      case 'module':
        this.router.navigateToRoute(res.name);
        this.clearSearch();
        break;

      case 'customer':
      case 'site':
        this.router.navigateToRoute('customer-detail', { Id: res.Id });
        this.clearSearch();
        break;

      case 'net':
        this.router.navigateToRoute('net-detail', { Id: res.Id });
        this.clearSearch();
        break;

      case 'specialproduct':
        this.router.navigateToRoute('special-product-detail', { Id: res.Id });
        this.clearSearch();
        break;

      case 'netoffer':
        this.router.navigateToRoute('offer-detail', {
          Id: res.Id,
          NetId: res.SecondaryId,
        });
        this.clearSearch();
        break;
      case 'simple-netoffer':
        this.router.navigateToRoute('simple-offer-detail', {
          Id: res.Id,
          NetId: res.SecondaryId,
        });
        this.clearSearch();
        break;
      case 'netorder':
        this.router.navigateToRoute('order-detail', {
          Id: res.Id,
          NetId: res.SecondaryId,
        });
        this.clearSearch();
        break;

      case 'service':
        this.dialogService
          .open({
            viewModel: ServiceDialog,
            model: { Id: res.Id, NetId: res.SecondaryId },
            lock: false,
            position: (mc, mo) => {
              //
            },
          })
          .whenClosed(() => {
            document.querySelector('html').style.overflowY = null;
          })
          .catch(() => {
            document.querySelector('html').style.overflowY = null;
          });
        this.clearSearch();
        break;

      default:
        break;
    }
  }

  private clearSearch() {
    this.searchString = '';
    this.searchBoxExpanded = false;
    this.searchBoxFocused = false;
    this.searchBoxClass = '';
    this.searchResults = [];
    this.focusedSearchResultItem = 0;
    this.focusedSearchResultEntity = 0;
    this.searchStringToShort = false;
  }

  blurSearch() {
    // Use timeout so click event in search results can fire first
    setTimeout(() => {
      this.clearSearch();
    }, 300);
  }

  private setFocusedSearchResult(entityIndex: number, itemIndex: number) {
    this.focusedSearchResultEntity = entityIndex;
    this.focusedSearchResultItem = itemIndex;
  }

  handleAppClicks(event: MouseEvent) {
    const target = event.target as HTMLElement;
    // Dont hide if click was sidebar left toogle
    if (target.parentElement.className === 'app__header__menu-left') {
      return true;
    }

    // Dont hide if click was sidebar right toogle
    if (target.parentElement.className === 'app__header__person') {
      return true;
    }

    // Hide sidebar right if open
    if (this.sidebarRightVisible) {
      this.sidebarRightVisible = false;
    }

    // Hide sidebar left if open
    if (this.sidebarLeftVisibleMobile) {
      this.sidebarLeftVisibleMobile = false;
    }

    return true;
  }

  handleLeftSidebarClicks() {
    // Hide sidebar left if open
    if (this.sidebarLeftVisibleMobile) {
      this.sidebarLeftVisibleMobile = false;
    }

    return true;
  }

  private handleAppHotkeys(event: KeyboardEvent) {
    // CTRL + space = activate search box
    if (event.which === 32 && event.ctrlKey) {
      this.toggleSearchBox();
    }
    return true;
  }

  setKeepMenuOpen(event: InputEvent) {
    const { checked } = event.target as HTMLInputElement;
    localStorage.setItem('KEEP_MENU_OPEN', checked?.toString());
  }

  private handlePrequeueIfMenuIsOpen() {
    const servicePrequeue = document.getElementById('service-prequeue-id');

    if (servicePrequeue == null) {
      return;
    }

    if (!this.keepMenuOpen) {
      servicePrequeue.classList.add('service-prequeue--fixed-closed');
      servicePrequeue.classList.remove('service-prequeue--fixed');
    } else {
      servicePrequeue.classList.add('service-prequeue--fixed');
      servicePrequeue.classList.remove('service-prequeue--fixed-closed');
    }
  }

  openMenu() {
    this.keepMenuOpen = !this.keepMenuOpen;
    this.appState.mutate({ action: 'setSidebarOpen', payload: this.keepMenuOpen });
    this.handlePrequeueIfMenuIsOpen();
  }
}
